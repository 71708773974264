import React from 'react'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  FilterByInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
} from '@src/interfaces/data'
import { kpisRequests } from '@src/api/kpis'
import { Statuses } from '@src/interfaces'

import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import Tooltip from '@components/Tooltip/Tooltip'
import IconButton from '@components/ButtonIcon/IconButton'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiPerformanceColumnWithLooker,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { FeatureFlags } from '@src/store/auth/types'
import { MoreBar, TableWidget, Icon } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useQuery } from '@src/utils/queryParamsHooks'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'

export const getKpiFilters = (cycleId: string): FilterByInterface[] => [
  ...getCommonFilters({ reviewCycle: { id: cycleId } }),
  {
    filters: [{ name: 'True', id: 'True' }],
    columnName: 'is_company',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting: SortByInterface[] = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
  {
    sortBy: 'has_children',
    direction: SORT_DIRECTION.ASC,
  },
]

export const CompanyTargets = ({
  initialCycle,
}: {
  initialCycle: ReviewCyclesInterface
}) => {
  const { query } = useQuery()
  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    getKpiFilters(query.cycle__id || String(initialCycle.id)),
    kpiSorting,
  )

  return (
    <KpiPage
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}

interface StructureProps {
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const departmentRow = (allowedLooker: boolean): RowInterface<KpiInterface> => ({
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 350,
    },
    {
      ...foreignGoalColumn,
      width: 200,
    },
    allowedLooker
      ? { ...kpiPerformanceColumnWithLooker, width: 100 }
      : {
          ...kpiPerformanceColumn,
          width: 100,
        },
    {
      ...kpiInitialValueColumn,
      width: 60,
    },
    {
      ...kpiCurrentValueColumn,
      width: 60,
    },
    {
      ...kpiTargetColumn,
      width: 60,
    },
    {
      ...kpiUnitColumn,
      width: 60,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 100,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 60,
    },
  ],
})

const KpiPage = ({ table, warnAboutMandatoryKPIs }: StructureProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const allowedLooker = featureFlags.includes(FeatureFlags.Allowed_Looker)

  const canRefreshKpis = true // fixme: define the rule

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          selector={selectorKeys.review_cycles}
          filter={table.filterBy}
          filterInputType={FilterSelectType.SingleSelect}
        />
        <OverallProgress
          value={table?.stats?.avg_performance}
          tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
        />
        {warnAboutMandatoryKPIs && (
          <Tooltip
            placement="right"
            text="This profile has mandatory KPIs which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
          >
            <IconButton>
              <Icon name="16/Warning" color="red" />
            </IconButton>
          </Tooltip>
        )}
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>{refreshButton}</MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.CompanyKPI}
          useWindowScroll
          dataType="Targets"
          row={departmentRow(allowedLooker)}
          {...table}
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your company targets to see them here."
        />
        {refreshNote}
      </TableWidget.Table>
    </TableWidget>
  )
}
