import {
  TableWidget,
  Token,
  Text,
  MoreBar,
  Icon,
  useStatusPopup,
  StatusPopup,
  MoreBarSkeleton,
  Flex,
} from '@revolut/ui-kit'
import { useTable } from '@src/components/Table/hooks'
import {
  GoalsStats,
  ManageGoalEntityDefinition,
  ManageGoalsPayload,
  SupportedEntityGraphPath,
  fetchEntityGoalsGraph,
  goalsListTableRequests,
  manageGoals,
} from '@src/api/goals'
import {
  OrgEntityInterface,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useEffect, useMemo, useState } from 'react'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsOrgUnitColumn,
} from '@src/constants/columns/goals'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { GoalKpiDetails, GoalWeightMode, GoalsInterface } from '@src/interfaces/goals'
import LapeForm, { useLapeContext } from '../Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import { captureException } from '@sentry/react'
import ConfirmationDialog from '../Popups/ConfirmationDialog'
import { roundFloat } from '@src/utils/numbers'
import { Statuses } from '@src/interfaces'
import { GoalTargetSidebarForm } from './GoalTargetSidebarForm'
import { OverallProgress } from './components/OverallProgress'
import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { rowHighlight } from '../KPI'
import { PermissionTypes } from '@src/store/auth/types'

interface GoalsTableWidgetProps {
  initialFilters: FilterByInterface[]
  entity: OrgEntityInterface
}

export const GoalsTableWidget = ({ initialFilters, entity }: GoalsTableWidgetProps) => {
  const defaultWeightMode =
    ('goal_weight_mode' in entity.data && entity.data.goal_weight_mode?.id) || 'automatic'
  const [weightMode, setWeightMode] = useState<GoalWeightMode>(defaultWeightMode)
  const table = useTable(goalsListTableRequests, initialFilters, undefined, {
    parentIdFilterKey: 'parent_id',
  })
  const form = useLapeContext<ManageGoalsPayload>()
  const statusPopup = useStatusPopup()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()

  const totalWeight = form.values.goals.map(g => g.weight).reduce((a, b) => a + b, 0)
  const [manageMode, setManageMode] = useState(false)

  const canManageGoals = entity.data.field_options.permissions?.includes(
    PermissionTypes.CanManageGoals,
  )

  useEffect(() => {
    form.reset({
      ...form.values,
      goals: table.data,
    })
  }, [table.data])

  const goalsIdsToApprove = table.data
    .filter(({ approval_status }) => approval_status.id !== ApprovalStatuses.Approved)
    .map(({ id }) => ({ id }))

  const getGoalUrl = (goal: GoalsInterface) => {
    const route =
      goal.status.id === Statuses.draft
        ? ROUTES.FORMS.GOAL.EDIT
        : ROUTES.FORMS.GOAL.PREVIEW

    return {
      pathname: pathToUrl(route, { id: goal.id }),
      search: new URLSearchParams(groupFiltersIntoURLQuery(table.filterBy)).toString(),
    }
  }

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(
    () => ({
      highlight: goal =>
        goal.status.id === Statuses.draft || goal.status.id === Statuses.archived
          ? Token.color.grey2
          : rowHighlight(goal.approval_status.id),
      cells: [
        {
          ...goalsNameColumn(getGoalUrl),
          width: 300,
        },
        entity.type === EntityTypes.company
          ? {
              ...goalsOrgUnitColumn,
              width: 150,
            }
          : null,
        {
          ...(manageMode ? goalsInputWeightColumn : goalsWeightColumn),
          width: 70,
        },
        {
          ...goalsChangeStatusColumn((status, goalId) =>
            table.updateRows(
              r => r.id === goalId,
              r => ({ ...r, status }),
            ),
          ),
          width: 100,
        },
        {
          ...goalsProgressColumn,
          width: 100,
        },
        {
          ...goalsOwnerColumn,
          width: 150,
        },
        {
          ...goalsApprovalStatusColumn,
          width: 50,
        },
      ].filter(Boolean),
    }),
    [manageMode, table.data, weightMode, selectedTarget],
  )

  const getManageGoalsEntityDefinition = (
    contentType?: any,
  ): ManageGoalEntityDefinition | null => {
    if (!entity) {
      return null
    }

    if (entity.type === EntityTypes.company) {
      return {
        is_company: true,
      }
    }
    if (contentType) {
      return { content_type: { id: contentType }, object_id: entity.data.id }
    }
    return null
  }

  const handleSubmit = async (autodistribute?: boolean) => {
    const values = form.values
    const contentType = table.data[0].content_type?.id
    const entityDefinition = getManageGoalsEntityDefinition(contentType)

    if (!entityDefinition) {
      captureException('Failed to determine entity definition for manage goals action')
      throw new Error('Please reload page and try again.')
    }

    const autodistributeWeights = autoDistributeWeights()

    const updatedForm = {
      ...form.values,
      ...entityDefinition,
      goal_weight_mode: autodistribute
        ? { id: 'automatic' as const }
        : { id: 'manual' as const },
      goals: values.goals.map(({ id, weight }, index) => ({
        id,
        weight: autodistribute ? autodistributeWeights[index] : weight,
      })),
      review_cycle: {
        id: String(
          table.filterBy.find(filter => filter.columnName === 'cycle__id')?.filters[0].id,
        ),
      },
    }

    try {
      await manageGoals(updatedForm)

      table.refresh()

      if (autodistribute) {
        setWeightMode('automatic')
      }

      setManageMode(false)

      return updatedForm
    } catch (err) {
      captureException(err)

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to update weights</StatusPopup.Title>
          <StatusPopup.Description>
            Something went wrong, please try again later.
          </StatusPopup.Description>
        </StatusPopup>,
      )

      throw err
    }
  }

  const autoDistributeWeights = (): number[] => {
    let sum = 0
    const count = table.data.length
    const roundedWeight = roundFloat(100 / count, 2)
    const minIncrement = 0.01
    const diff = roundFloat(100 - roundedWeight * count, 2) / minIncrement

    return table.data.map((goal, index) => {
      if (index < diff) {
        const weight = roundedWeight + minIncrement

        sum += weight

        return weight
      }
      if (goal === table.data.at(-1)) {
        return roundFloat(100 - sum, 2)
      }

      sum += roundedWeight

      return roundedWeight
    })
  }

  const entityTypePerformanceGraphPath: SupportedEntityGraphPath | undefined = {
    [EntityTypes.company]: 'company' as const,
    [EntityTypes.department]: 'departments' as const,
    [EntityTypes.team]: 'teams' as const,
    [EntityTypes.teams]: 'teams' as const,
    [EntityTypes.employee]: 'employees' as const,
    [EntityTypes.employees]: 'employees' as const,
    [EntityTypes.function]: undefined,
    [EntityTypes.role]: undefined,
    [EntityTypes.specialisation]: undefined,
  }[entity.type]

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <GoalCycleFilter
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            selector={selectorKeys.review_cycles}
            filter={table.filterBy}
          />
          <OverallProgress
            value={table.stats?.overall_progress}
            fetchData={
              entityTypePerformanceGraphPath
                ? fetchEntityGoalsGraph(entityTypePerformanceGraphPath)
                : undefined
            }
            id={entity?.data.id}
          />
        </TableWidget.Info>
        <TableWidget.Actions>
          {table.loading ? (
            <MoreBarSkeleton />
          ) : (
            <MoreBar>
              <BulkGoalApproveAction
                goals={goalsIdsToApprove}
                onApproved={table.refresh}
                title={
                  goalsIdsToApprove.length === table.data.length
                    ? 'Approve all goals'
                    : undefined
                }
              />
              <AddGoalAction />
              {table.data.length > 0 && canManageGoals && (
                <MoreBar.Action
                  useIcon={manageMode ? 'SwitchOn' : 'SwitchOff'}
                  onClick={() => setManageMode(current => !current)}
                >
                  Edit weights
                </MoreBar.Action>
              )}
              {manageMode && (
                <MoreBar.Action
                  useIcon="AutoExchange"
                  onClick={() => setConfirmOpen(true)}
                >
                  Auto-distribute weights
                </MoreBar.Action>
              )}
            </MoreBar>
          )}
        </TableWidget.Actions>
        <TableWidget.Table>
          {manageMode ? (
            <LapeEditableTable<GoalsInterface>
              dataFieldName="goals"
              name={TableNames.Goals}
              dataType="Goal"
              disableFilters={manageMode}
              {...table}
              initialData={table.data}
              row={row}
              replaceOnInitialDataChange
            />
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              dataType="Goal"
              {...table}
              row={row as RowInterface<GoalsInterface>}
              expandableType="chevron"
              useFetchedChildren
            />
          )}

          {manageMode &&
          roundFloat(totalWeight, 2) !== 100 &&
          !table.loading &&
          table.data.length ? (
            <Flex alignItems="center" mt="s-16" gap="s-8">
              <Icon name="ExclamationMarkOutline" color={Token.color.orange} size={16} />
              <Text color={Token.color.greyTone50} variant="caption">
                Custom weights defined - your weights don’t add up to 100%. Current value
                is {roundFloat(totalWeight, 2)}%
              </Text>
            </Flex>
          ) : null}
        </TableWidget.Table>
      </TableWidget>
      {manageMode && form.dirty && (
        <PageActions mt="s-32" maxWidth="100%">
          <NewSaveButtonWithPopup
            onClick={() => handleSubmit()}
            successText="Goal weights have been updated"
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
      <ConfirmationDialog
        variant="compact"
        open={confirmOpen}
        label="Confirm auto distribution of weights"
        body="This will equally distribute the goals weights and overwrite the current values."
        yesMessage="Continue"
        noMessage="Cancel"
        onClose={() => setConfirmOpen(false)}
        onReject={() => setConfirmOpen(false)}
        onConfirm={() => {
          setConfirmOpen(false)
          handleSubmit(true)
        }}
      />
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedTarget(undefined)
        }}
      />
    </>
  )
}

export const GoalsTableFormWidget = ({
  initialFilters,
}: {
  initialFilters: FilterByInterface[]
}) => {
  const { entity } = useOrgEntity()

  const initialValues = useMemo(() => {
    return entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode
      ? {
          goals: [],
          goal_weight_mode: entity.data.goal_weight_mode,
        }
      : {
          goals: [],
          goal_weight_mode: { id: 'automatic' },
        }
  }, [entity])

  return entity ? (
    <LapeForm
      disableValidation
      onSubmit={() => Promise.resolve({})}
      initialValues={initialValues}
    >
      <GoalsTableWidget initialFilters={initialFilters} entity={entity} />
    </LapeForm>
  ) : null
}
