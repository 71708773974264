import React, { useContext, useState } from 'react'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import { DeliverablesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/DeliverablesCard'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import { ManagerSkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/ManagerSkillsCard'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { Visibility } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Visibility'
import { SaveDraftButton } from '@src/pages/Forms/EmployeePerformanceLayout/SaveDraftButton'
import { SubmitButton } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'
import { PageActions } from '@components/Page/PageActions'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPIsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/KPIsCard'
import { EmployeePerformanceInterface } from '@src/pages/Forms/EmployeePerformanceLayout/index'
import {
  getRecommendedGrade,
  getSkillsMissingJustification,
  RecommendedGradesInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import get from 'lodash/get'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'

const RecommendedGradesContext = React.createContext<RecommendedGradesInterface>({
  recommendedGrade: null,
  grades: null,
})

export const useRecommendedGradesContext = () => {
  return useContext(RecommendedGradesContext)
}

export const getRecommendationAndType = (values: ReviewScorecardInterface) => {
  const lmRecommendation = values.review_data.line_manager_extra_section
  const fmRecommendation = values.review_data.functional_manager_extra_section
  const peerRecommendation = values.review_data.peer_extra_section
  const probationPipRecommendation = values.review_data.recommendation_extra_section
  const obsoletePipRecommendation = values.review_data.pip_extra_section

  if (lmRecommendation) {
    return {
      recommendation: lmRecommendation,
      type: RecommendationTypes.LM,
      field: 'review_data.line_manager_extra_section',
    }
  }
  if (fmRecommendation) {
    return {
      recommendation: fmRecommendation,
      type: RecommendationTypes.FM,
      field: 'review_data.functional_manager_extra_section',
    }
  }
  if (peerRecommendation) {
    return {
      recommendation: peerRecommendation,
      type: RecommendationTypes.PEER,
      field: 'review_data.peer_extra_section',
    }
  }
  if (probationPipRecommendation) {
    return {
      recommendation: probationPipRecommendation,
      type: RecommendationTypes.PIP,
      field: 'review_data.recommendation_extra_section',
    }
  }
  if (obsoletePipRecommendation) {
    return {
      recommendation: obsoletePipRecommendation,
      type: RecommendationTypes.PIP_V1,
      field: 'review_data.pip_extra_section',
    }
  }

  return null
}

export const ScorecardContent = connect(
  ({ latestValues }: EmployeePerformanceInterface) => {
    const { values } = useLapeContext<ReviewScorecardInterface>()
    const [submitChecked, setSubmitChecked] = useState(false)
    const [missingKpiJustification, setMissingKpiJustification] = useState(false)
    const [missingDeliverablesJustification, setMissingDeliverablesJustification] =
      useState(false)
    const [isHelpOpen, setHelpOpen] = useState<HelpTabs | undefined>()
    const recommendationSection = getRecommendationAndType(values)
    const gradesMap = useGetReviewGradesMap()
    const grades = getRecommendedGrade(latestValues)
    const { skillsMissingJustification } = getSkillsMissingJustification(latestValues)

    if (recommendationSection && grades.recommendedGrade) {
      const recommendation = get(
        values,
        recommendationSection.field,
      )?.employee_project_performance

      if (recommendation && !recommendation.value) {
        recommendation.value = grades.recommendedGrade
      }
    }

    const employeePerformance =
      recommendationSection?.recommendation.employee_project_performance
    const employeePerformanceValue = employeePerformance?.value
    const gradeDifferExpectation =
      !!employeePerformanceValue && employeePerformanceValue !== grades.recommendedGrade
    const missingFeedbackJustification =
      !employeePerformance?.justification && gradeDifferExpectation

    const onHelpTooltipClick = (helpTab: HelpTabs) => {
      if (isHelpOpen) {
        setHelpOpen(undefined)
      }
      setHelpOpen(helpTab)
    }

    const beforeSubmitCheck = () => {
      setSubmitChecked(
        missingKpiJustification ||
          missingDeliverablesJustification ||
          missingFeedbackJustification ||
          !!skillsMissingJustification?.length,
      )
      const checksPassed =
        !missingKpiJustification &&
        !missingDeliverablesJustification &&
        !missingFeedbackJustification &&
        !skillsMissingJustification?.length

      if (!checksPassed) {
        pushNotification({
          value: 'Please review the form, some justifications are missing',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.warning,
        })
      }
      return checksPassed
    }

    const commonProps = {
      onHelpClick: onHelpTooltipClick,
      gradesMap,
    }

    return (
      <RecommendedGradesContext.Provider value={grades}>
        <PerformanceHeader isHelpOpen={isHelpOpen} setHelpOpen={setHelpOpen} />
        <VStack space="s-16">
          <KPIsCard
            {...commonProps}
            setMissingKpiJustification={setMissingKpiJustification}
            showBeforeSubmitCheckError={submitChecked && missingKpiJustification}
          />
          <DeliverablesCard
            {...commonProps}
            setMissingDeliverablesJustification={setMissingDeliverablesJustification}
            showBeforeSubmitCheckError={submitChecked && missingDeliverablesJustification}
          />
          <SkillsCard
            {...commonProps}
            skillsMissingJustification={submitChecked ? skillsMissingJustification : []}
          />
          <ManagerSkillsCard {...commonProps} />
          <CultureValuesCard {...commonProps} />
          {recommendationSection && (
            <BarRaiser
              isViewMode={false}
              questions={
                recommendationSection.recommendation.keeper_test_section.questions
              }
              type={recommendationSection.type}
              field={recommendationSection.field}
            />
          )}
          <Feedback
            recommendationData={recommendationSection?.recommendation}
            type={recommendationSection?.type}
            showMissingJustificationError={submitChecked && missingFeedbackJustification}
          />
          <AdditionalQuestions data={values.review_data} isViewMode={false} />
          <Visibility />
          <PageActions maxWidth="100%">
            <Flex gap="s-8" justifyContent="center">
              <SaveDraftButton />
              <SubmitButton beforeSubmitCheck={beforeSubmitCheck} />
            </Flex>
          </PageActions>
        </VStack>
      </RecommendedGradesContext.Provider>
    )
  },
)
