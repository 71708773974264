import { InputGroup } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { strategyLabelsMap } from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/common'
import { IdAndName } from '@src/interfaces'
import { KpiGoals, KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import React, { useEffect, useState } from 'react'
import { QueryField } from './QueryField'
import { mergeFormValues } from '@src/utils/form'
import { LapeCodeEditorProps } from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'

export const TargetFormFields = ({
  hideLooker = false,
  codeEditorProps,
  variant,
}: {
  hideLooker?: boolean
  codeEditorProps?: Partial<LapeCodeEditorProps>
  variant?: 'new' | 'side-edit'
}) => {
  const { values, changelog } = useLapeContext<KpiInterface>()
  const [previousValues, setPreviousValues] = useState<KpiInterface | undefined>()
  const { options: kpiGoalOptions } = useFetchOptions<IdAndName<KpiGoals>>(
    selectorKeys.kpi_goals,
    true,
  )

  useEffect(() => {
    setPreviousValues(changelog && mergeFormValues(values, changelog))
  }, [values, changelog])

  const isInMaintainMode =
    values.targets?.[0].kpi_goal &&
    [KpiGoals.keep_target, KpiGoals.keep_target_down, KpiGoals.keep_target_up].includes(
      values.targets?.[0].kpi_goal,
    )

  const noKpiGoalOption =
    !!kpiGoalOptions.length &&
    !!values.targets?.[0]?.kpi_goal &&
    !kpiGoalOptions.find(opt => opt.value.id === values.targets?.[0].kpi_goal)

  return (
    <InputGroup>
      <LapeRadioSelectInput
        name="unit"
        label="Unit of measure"
        selector={selectorKeys.kpi_units}
        useQuery
        value={
          values.unit
            ? {
                name: values.unit,
              }
            : null
        }
        onChange={option => {
          if (option?.name) {
            values.unit = option.name
          }
        }}
      />
      <LapeRadioSelectInput
        name="kpi_goal"
        clearable={false}
        options={kpiGoalOptions}
        label="Progress direction"
        value={
          values.targets?.[0].kpi_goal && {
            id: values.targets[0].kpi_goal,
            name: strategyLabelsMap[values.targets[0].kpi_goal],
          }
        }
        onChange={option => {
          if (option) {
            values.targets[0].kpi_goal = option.id
          }
        }}
        message={noKpiGoalOption ? 'Progress direction needs to be changed' : undefined}
        hasError={noKpiGoalOption}
      />
      {variant === 'new' && (
        <InputGroup variant="horizontal">
          <LapeNewInput
            type="number"
            name="targets.0.initial_value"
            required
            label={isInMaintainMode ? 'Lower limit' : 'Initial value'}
            onAfterChange={value => {
              values.targets[0].initial_value = value !== '' ? Number(value) : null
            }}
          />
          <LapeNewInput
            type="number"
            name="targets.0.target"
            required
            label={isInMaintainMode ? 'Upper limit' : 'Target value'}
            onAfterChange={value => {
              values.targets[0].target = value !== '' ? Number(value) : null
            }}
          />
        </InputGroup>
      )}
      {variant === 'side-edit' && (
        <>
          <LapeNewInput
            type="number"
            name="targets.0.initial_value"
            required
            label={isInMaintainMode ? 'Lower limit' : 'Initial value'}
            onAfterChange={value => {
              values.targets[0].initial_value = value !== '' ? Number(value) : null
            }}
          />
          {values.update_type === UpdateTypes.manual && (
            <LapeNewInput
              type="number"
              name="current_progress"
              label="Current value"
              required
              onAfterChange={value => {
                values.targets[0].current_value = Number(value)
              }}
            />
          )}
          <LapeNewInput
            type="number"
            name="targets.0.target"
            required
            label={isInMaintainMode ? 'Upper limit' : 'Target value'}
            onAfterChange={value => {
              values.targets[0].target = value !== '' ? Number(value) : null
            }}
          />
        </>
      )}

      {(values.update_type === UpdateTypes.sql ||
        values.update_type === UpdateTypes.looker) && (
        <QueryField
          hideLooker={hideLooker}
          previousQuery={previousValues?.sql_query}
          codeEditorProps={codeEditorProps}
        />
      )}
    </InputGroup>
  )
}
