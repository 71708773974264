import React, { useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface, GoalKpiDetails } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'
import { IdAndName } from '@src/interfaces'
import { CellWithItem } from '../../../common/CellWithItem'
import AddGoalTargetForm from '../../SidebarForms/AddGoalTargetForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Avatar, HStack, Text, TextButton } from '@revolut/ui-kit'

import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { TargetCell } from './TargetCell'

interface TargetsWidgetProps {
  reviewCycle: ReviewCyclesInterface
}

const goalTargetToKpi = (target: GoalKpiDetails): KpiInterface => ({
  ...target,
  status: target.status?.id,
})

export const TargetsWidget = ({ reviewCycle }: TargetsWidgetProps) => {
  const { values } = useLapeContext<GoalsInterface>()

  const { getEntityProps } = useOrgEntity()

  const isCascaded = values.update_type?.id === 'cascaded'

  const editTitle = isCascaded ? 'Parent targets' : 'Add targets'
  const [expandedIndex, setExpandedIndex] = useState<number>()

  useEffect(() => {
    // we want to collapse just submitted target
    // btoa will be different because id value will be updated
    // HEAD UP: will be also triggered by sorting but so far sorting is not supported by this table

    if (expandedIndex) {
      setExpandedIndex(undefined)
    }
    // btoa is deprecated only for NODE, doesn't have alternative in browser
  }, [
    btoa(
      values.kpis
        .map(kpi => kpi.id)
        .filter(Boolean)
        .join(','),
    ),
  ])

  const emptyTarget = {
    ...getEntityProps(),
    tempId: Math.ceil(Math.random() * 10e6),
    owner: values.owner as EmployeeOptionInterface & { team: IdAndName },
    goal: { id: values.id },
    targets: [{ review_cycle: reviewCycle }],
    update_type: undefined,
  } as unknown as GoalKpiDetails & { tempId?: number }

  useEffect(() => {
    if (values.kpis.length === 0) {
      values.kpis.push(emptyTarget)
      setExpandedIndex(0)
    }
  }, [values.kpis.length])

  const onAddClicked = async () => {
    values.kpis.push(emptyTarget)
    setExpandedIndex(values.kpis.length - 1)
  }

  const renderCards = (cards: GoalKpiDetails[]) => {
    return cards.map((target, index) => {
      return (
        <TargetCell
          allowDelete={values.kpis.length > 1}
          target={target}
          index={index + 1}
          key={target.id}
          expanded={expandedIndex === index}
          onToggle={() => {
            return expandedIndex === index
              ? setExpandedIndex(undefined)
              : setExpandedIndex(index)
          }}
        >
          <AddGoalTargetForm initialValues={goalTargetToKpi(target)} />
        </TargetCell>
      )
    })
  }

  return (
    <CellWithItem
      icon="TurboTransfer"
      title={editTitle}
      description="What are the success criteria and how is progress measured?"
    >
      {renderCards(values.kpis)}

      {values.update_type?.id === 'target_based' ? (
        <TextButton onClick={onAddClicked}>
          <HStack align="center" space="s-12">
            <Avatar useIcon="16/Plus" size={32} />
            <Text>Add another target</Text>
          </HStack>
        </TextButton>
      ) : null}
    </CellWithItem>
  )
}
