import React from 'react'
import { useTable } from '@components/Table/hooks'
import {
  FilterByInterface,
  SORT_DIRECTION,
  Stats,
  RowInterface,
} from '@src/interfaces/data'
import { kpisRequests } from '@src/api/kpis'
import { useTableReturnType } from '@components/Table/hooks'
import { TeamInterface } from '@src/interfaces/teams'
import { KpiInterface } from '@src/interfaces/kpis'
import { getKpiPerformanceGraph } from '@src/api/teams'
import Tooltip from '@components/Tooltip/Tooltip'
import { Statuses } from '@src/interfaces'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { PermissionTypes } from '@src/store/auth/types'
import { IconButton as NewIconButton, MoreBar, TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'

interface StructureProps {
  data: TeamInterface
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const ROW: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 350,
    },
    {
      ...foreignGoalColumn,
      width: 200,
    },
    {
      ...kpiPerformanceColumn,
      width: 100,
    },
    {
      ...kpiInitialValueColumn,
      width: 70,
    },
    {
      ...kpiCurrentValueColumn,
      width: 70,
    },
    {
      ...kpiTargetColumn,
      width: 70,
    },
    {
      ...kpiUnitColumn,
      width: 50,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 100,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 70,
    },
  ],
}

const KPI = ({ data, table, warnAboutMandatoryKPIs }: StructureProps) => {
  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          filter={table.filterBy}
          filterInputType={FilterSelectType.SingleSelect}
          selector={selectorKeys.review_cycles}
        />
        <OverallProgress
          value={table?.stats?.avg_performance}
          id={data.id}
          fetchData={getKpiPerformanceGraph}
          tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
        />
        {warnAboutMandatoryKPIs && (
          <Tooltip
            placement="right"
            text="This profile has mandatory KPIs which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
          >
            <NewIconButton useIcon="16/Warning" color="red" />
          </Tooltip>
        )}
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>{refreshButton}</MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.TeamKPI}
          dataType="KPI"
          row={ROW}
          {...table}
          useWindowScroll
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your team KPIs."
          useFetchedChildren
        />
        {refreshNote}
      </TableWidget.Table>
    </TableWidget>
  )
}

const getKpiFilters = (
  data: TeamInterface,
  reviewCycle: ReviewCyclesInterface,
): FilterByInterface[] => [
  ...getCommonFilters({ reviewCycle }),
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName: 'team__id',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
]

export const TeamTargets = ({
  data,
  cycle,
}: {
  data: TeamInterface
  cycle: ReviewCyclesInterface
}) => {
  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    getKpiFilters(data, cycle),
    kpiSorting,
  )

  return (
    <KPI
      data={data}
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}
